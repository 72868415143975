body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbarSticky {
    position: sticky !important;
    top: 0rem;
    z-index: 9;
}

hr {
    border-color: white;
    background-color: white;
    height: 1.5px;
    width: 88%;
    margin: 0 !important;
}

.navbar .navCart {
    text-decoration: none !important;
    color: white !important;
}

.cont {
    height: 6rem;
    overflow: hidden;
    width: 20%;
}

.goldServiceLogo {
    height: 100%;
    background-size: cover;
}

.divContainer {
    display: flex;
    height: 4rem;
    width: 12%;
    margin-left: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.divContainer:hover * {
    filter: invert(0.4) sepia(1) hue-rotate(20deg) saturate(100%);
}

.nameStyle {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: white;
}

.navContainer {
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.onHover:hover * {
    filter: invert(0.4) sepia(1) hue-rotate(20deg) saturate(100%);
}

.background-color-dark {
    background-color: #0A0B0E;
}

.dropStyle {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 10vw;
    height: 10vh;
    background-color: #ffffff;
    border-radius: 0.2rem;
    color: black;
    z-index: 10;
}

.divOption {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.bg-color-black {
    background-color: #111410;
}

.modalResponsive {
    width: 17vw;
}

.detailImg {
    width: 5vw;
}

.noDisplay {
    display: inherit;
}

/* Responsive navbar */

@media only screen and (max-width: 435px) {
    .contResponsive {
        justify-content: center !important;
        flex-wrap: wrap !important;
        height: 9rem !important;
    }

    .inputResponsive {
        width: 84vw !important;
    }

    .fixedMenu {
        position: fixed;
        bottom: 0;
    }

    .modalResponsive {
        width: 70vw;
    }

    .detailImg {
        width: 15vw !important;
    }

    .notification {
        left: 1.3rem;
        top: 1rem;
    }

    .resize {
        margin-top: 0.8rem;
        height: 3rem !important;
    }
}

@media only screen and (max-width: 800px) {
    .divContainer {
        width: auto;
    }
}

@media only screen and (max-width: 1200px) {
    .divContainer {
        width: 14.5%;
    }
}

@media only screen and (max-width: 1003px) {
    .noDisplay {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .noCategories {
        display: none;
    }
}

@media only screen and (max-width: 737px) {
    .noCategories {
        display: none;
    }
}